import { Me, Login, Logout } from '~/apollo/auth.gql';


export const state = () => ({
    initialized: false,
    authenticated: false,
    me: null,
    loginMessage: null,
});


export const mutations = {
    initialized(state, data) {
        state.initialized = true;
    },
    loggedIn(state, { me }) {
        state.authenticated = true;
        state.me = me;
    },
    loggedOut(state) {
        state.authenticated = false;
        state.me = null;
    },
    loginMessagePushed(state, { message }) {
        state.loginMessage = message;
    },
    loginMessagePulled(state) {
        state.loginMessage = null;
    },
};


export const actions = {
    pushLoginMessage({ commit }, { message }) {
        commit('loginMessagePushed', { message });
    },
    pullLoginMessage({ state, commit }) {
        const message = state.loginMessage;
        commit('loginMessagePulled');
        return message;
    },
    async connectTablet({ dispatch }, { token }) {
        await this.$apolloHelpers.onLogin(token);
        await dispatch('fetchMe');
    },
    async login({ dispatch }, params) {
        const result = await dispatch('apollo/mutate', {
            mutation: Login,
            variables: {
                input: params,
            },
        }, { root: true });
        if (result.token) {
            await this.$apolloHelpers.onLogin(result.token);
            await dispatch('fetchMe');
        }
        return result;
    },
    async logout({ dispatch, commit }, { localOnly } = {}) {
        if (!localOnly) {
            await dispatch('apollo/mutate', {
                mutation: Logout,
            }, { root: true });
        }
        await this.$apolloHelpers.onLogout();
        commit('loggedOut');
    },
    async initialize({ state, dispatch, commit }) {
        if (state.initialized) {
            return;
        }
        const token = this.$apolloHelpers.getToken();
        if (token) {
            await dispatch('fetchMe');
        }
        commit('initialized');
    },
    async fetchMe({ dispatch, commit }) {
        try {
            const result = await dispatch('apollo/query', {
                query: Me,
            }, { root: true });

            // handle incomplete result (tablet account with deleted patient)
            // -- maybe caused by inconsistent db
            if (result.me.__typename === 'TabletAccount' && !result.me.patient) {
                throw new Error('tablet account is incomplete');
            }

            commit('loggedIn', { me: result.me });
        } catch (error) {
            await this.$apolloHelpers.onLogout();
            commit('loggedOut');
        }
    },
};
