import { SubmitTabletAccount } from '~/apollo/tablet-account.gql';


export const state = () => ({
    patient: null,
});


export const mutations = {
    patientUpdated(state, patient) {
        state.patient = patient;
    },
};


export const actions = {
    submit({ dispatch }, { _id, ...input }) {
        return dispatch('apollo/mutate', {
            mutation: SubmitTabletAccount,
            variables: { _id, input },
        }, { root: true });
    },
    setPatient({ commit }, patient) {
        commit('patientUpdated', patient);
    },
};
