import { ActivatePatientAccount, ContinuePatientSurvey } from '~/apollo/patient.gql';


export const actions = {
    activateAccount({ dispatch }, input) {
        return dispatch('apollo/mutate', {
            mutation: ActivatePatientAccount,
            variables: { input },
        }, { root: true });
    },
    continueSurvey({ dispatch }, { _id, ...input }) {
        return dispatch('apollo/mutate', {
            mutation: ContinuePatientSurvey,
            variables: { _id, input },
        }, { root: true });
    },
};
