//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            me: state => state.auth.me,
            breadcrumbs: state => state.breadcrumbs,
        }),
    },
    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout');
            this.$router.push({ name: 'auth-login' });
        },
    },
};
