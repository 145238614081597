
const LOCAL_STORAGE_LIFETIME_MS = 60 * 60 * 1000; // 1 hour


export const state = () => ({
    initialized: false,
    breadcrumbs: [],
});


export const getters = {
    homeRoute(state) {
        if (!state.auth.authenticated) {
            return { name: 'auth-login' };
        }
        const { me } = state.auth;
        switch (me.__typename) {
            case 'Patient':
                return { name: 'surveys' };
            case 'TabletAccount':
                return { name: 'tablet', params: { tabletAccountId: me._id } };
        }
    },
};


export const mutations = {
    initialized(state, data) {
        state.initialized = true;
    },
    breadcrumbsUpdated(state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs.length ? breadcrumbs : [{ text: '...' }];
    },
};


export const actions =
{
    async initialize({ state, dispatch, commit }) {
        if (state.initialized) {
            return;
        }
        this.app.router.afterEach(() => {
            dispatch('updateBreadcrumbs');
        });
        await dispatch('auth/initialize', null, { root: true });
        await dispatch('cleanStorage');
        commit('initialized');
    },
    updateBreadcrumbs({ commit }) {
        const breadcrumbs = [];
        for (const match of this.app.router.currentRoute.matched) {
            const instance = match.instances.default;
            if (!instance) {
                continue;
            }
            if (instance.breadcrumbs) {
                breadcrumbs.push(...instance.breadcrumbs());
            }
        }
        commit('breadcrumbsUpdated', breadcrumbs);
    },
    cleanStorage({ commit }) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const data = localStorage.getItem(key);
            try {
                const parsed = JSON.parse(data);
                if (!parsed.createdAt || parsed.createdAt + LOCAL_STORAGE_LIFETIME_MS < Date.now()) {
                    localStorage.removeItem(key);
                }
            } catch {
                localStorage.removeItem(key);
            }
        }
    },
};
