import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _368b5adc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _527ec100 = () => interopDefault(import('../pages/auth/activate.vue' /* webpackChunkName: "pages/auth/activate" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9a19d152 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _781a7233 = () => interopDefault(import('../pages/auth/tablet.vue' /* webpackChunkName: "pages/auth/tablet" */))
const _01a21db8 = () => interopDefault(import('../pages/site-notice.vue' /* webpackChunkName: "pages/site-notice" */))
const _5cd9e7ea = () => interopDefault(import('../pages/survey-finished.vue' /* webpackChunkName: "pages/survey-finished" */))
const _6ac17622 = () => interopDefault(import('../pages/surveys/index.vue' /* webpackChunkName: "pages/surveys/index" */))
const _0eecd7d0 = () => interopDefault(import('../pages/tablet.vue' /* webpackChunkName: "pages/tablet" */))
const _f47f449e = () => interopDefault(import('../pages/surveys/detail.vue' /* webpackChunkName: "pages/surveys/detail" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _368b5adc,
    name: "auth",
    children: [{
      path: "activate",
      component: _527ec100,
      name: "auth-activate"
    }, {
      path: "login",
      component: _8e1272a8,
      name: "auth-login"
    }, {
      path: "logout",
      component: _9a19d152,
      name: "auth-logout"
    }, {
      path: "/auth/tablet/:token",
      component: _781a7233,
      name: "auth-tablet"
    }]
  }, {
    path: "/site-notice",
    component: _01a21db8,
    name: "site-notice"
  }, {
    path: "/survey-finished",
    component: _5cd9e7ea,
    name: "survey-finished"
  }, {
    path: "/surveys",
    component: _6ac17622,
    name: "surveys"
  }, {
    path: "/tablet/:tabletAccountId/:questionaireId?",
    component: _0eecd7d0,
    name: "tablet"
  }, {
    path: "/surveys/:surveyId/:questionaireId?",
    component: _f47f449e,
    name: "surveys-detail"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
