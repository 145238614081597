import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';


if (process.env.sentryDsn) {
    Sentry.init({
        Vue,
        dsn: process.env.sentryDsn,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
        environment: process.env.sentryEnvironment,
    });
}
