import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import schema from '../apollo/schema.json';


const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema,
});


export default function(context) {
    return {
        httpEndpoint: context.env.httpEndpoint,
        cache: new InMemoryCache({ fragmentMatcher }),
    };
}
