import { UpdateSurvey } from '~/apollo/survey.gql';


export const actions = {
    update({ dispatch }, { _id, ...input }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateSurvey,
            variables: { _id, input },
        }, { root: true });
    },
};
